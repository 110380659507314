<template>
  <div class="uk-form-stacked">
    <h4 class="uk-heading-line">
      User Info
    </h4>
    <div
      v-if="loadingDetail"
      class="uk-flex uk-flex-center uk-flex-middle"
    >
      <div uk-spinner />
    </div>
    <div
      v-else
      class="uk-child-width-expand@s uk-grid-medium"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              User ID
            </label>
            <div class="uk-form-controls">
              <input
                name="user_id"
                :value="detail_user.user_code || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              User Name
            </label>
            <div class="uk-form-controls">
              <input
                name="username"
                :value="detail_user.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Email
            </label>
            <div class="uk-form-controls">
              <input
                name="email"
                :value="detail_user.email || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Facility Type
            </label>
            <div class="uk-form-controls">
              <input
                name="facility_type"
                :value="listFacilityUser.data == undefined ? '-' : listFacilityUser.data[0]?.facility_type"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
          
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Phone Number
            </label>
            <div class="uk-form-controls">
              <input
                name="phone_number"
                :value="detail_user.phone || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Farmer Name
            </label>
            <div class="uk-form-controls">
              <input
                name="partner_name"
                :value="detail_user.partner?.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Role
            </label>
            <div class="uk-form-controls">
              <input
                name="role"
                :value="detail_user.role?.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Facility Name
            </label>
            <div class="uk-form-controls">
              <input
                name="facility_name"
                :value="listFacilityUser.data == undefined ? '-' : listFacilityUser.data[0]?.facility_name"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
      
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Status
            </label>
            <div v-if="detail_user.status === 0">
              <label-status
                status="inactive"
                :label="`${status.INACTIVE}`"
                table="false"
              />
            </div>
            <div v-else-if="detail_user.status === 1">
              <label-status
                status="active"
                :label="`${status.ACTIVE}`"
                table="false"
              />
            </div>
            <div v-else-if="detail_user.status === 2">
              <label-status
                status="unverified"
                :label="`${status.UNVERIFIED}`"
                table="false"
              />
            </div>
            <div v-else-if="detail_user.status === 3">
              <label-status
                status="active"
                :label="`${status.VERIFIED}`"
                table="false"
              />
            </div>
          </div> 
        </div>
      </div>
    </div>
    
                
    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button red uk-margin-right"
        :class="isCanAccessUser('delete', 'USER') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('delete', 'USER')"
        @click="showDeleteConfirmModal"
      >
        <img
          :src="`${images}/icon/ic_delete_red.svg`"
          alt="delete"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Delete
      </button>
      <button
        v-if="user.role == 'Super Admin'"
        class="uk-button soft-green uk-margin-right"
        @click="handleResendEmail"
      >
        <img
          :src="`${images}/icon/ic_resend_email_green.svg`"
          alt="resend"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Resend Email Verification
      </button>
      <button
        class="uk-button green"
        :class="isCanAccessUser('edit', 'USER') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('edit', 'USER')"
        @click.prevent="$router.push({ name: 'UserEdit', params: detail_user.id })"
      >
        <img
          :src="`${images}/icon/ic_edit_white.svg`"
          alt="edit"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STATUS } from '@/utils/constant'
import LabelStatus from '@/components/globals/LabelStatus'
import { isCanAccess, getUserProfile } from '@/utils/auth'

export default {
  components: {
    LabelStatus
  },
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    loadingDetail: {
      required: false,
      type: Boolean
    },
    listFacilityUser: {
      required: true,
      type: Object
    },
    // eslint-disable-next-line vue/require-default-prop
    filterRole: {
      required: false,
      type: Object
    },
    handleResendEmail: {
      required: true,
      type: Function
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  data() {
    return{
      status: STATUS
    }
  },
  computed: {
    ...mapGetters({
      detail_user: 'user/detail_user'
    }),
    user() {
      return getUserProfile()
    }
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    showDeleteConfirmModal() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: open,
        title: 'Delete Confirmation',
        description: 'Are you sure want to delete this data?',
        customTitleBtnOk: 'Delete',
        customTitleBtnCancel: 'Cancel',
        type: 'delete',
        loadingTable: false,
        loadingPopUp: false,
        id: this.id
      })
    }
  }
}
</script>
