<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1 uk-flex uk-flex-middle">
      <div class="uk-margin-right">
        <img
          :src="`${images}/icon/ic_arrow_left_black.svg`"
          class="cursor-pointer"
          alt=""
          @click.prevent="$router.push({ name: 'User'})"
        >
      </div>
      <div>
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top-medium base-card">
      <detail
        :id="id"
        :images="images"
        :list-facility-user="listFacilityUser"
        :loading-detail="loadingDetail"
        :filter-role="filterRole"
        :handle-resend-email="handleResendEmail"
        :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
        :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      />
    </div>

    <modal-add-edit-discard
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import { PREFIX_IMAGE } from "@/utils/constant"
import getHeaderTitle from '@/utils/header-title'
import Detail from './Detail'
import ModalAddEditDiscard from "@/components/globals/modals/ModalAddEditDiscard"

export default {
  components: {
    Detail,
    ModalAddEditDiscard
  },
  props: {
    id:{
      required: true,
      type: String
    },
    dataRole: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      loadingDetail: true,
      filterRole: {}
    }
  },
  computed: {
    ...mapGetters({
      listFacilityUser: 'user/list_facility_user',
      getDataModalAddEdit: 'user/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'user/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    this.filterRole = this.dataRole.find((item) => item.name.split(" ").join(" ").toUpperCase() == 'USER')
    await this.actionGetFacilityUser({ user_id: this.id, page: 1, limit: 10 })
    this.loadPage()
  },
  methods: {
    ...mapActions({
      actionGetFacilityUser: 'user/getFacilityUser',
      actionGetDetailUser: 'user/getDetailUser',
      actionEmailVerification: 'user/emailVerification',
      deleteUser: 'user/deleteUser'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'user/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'user/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    async loadPage() {
      this.loadingDetail
      await this.actionGetDetailUser(this.id)
      this.loadingDetail = false
    },
    handleResendEmail(){
      this.actionEmailVerification(this.id).then(result => {
        if(result?.message == 'OK') {
          setTimeout(() => {
            this.loadingDetail = true
          }, 500)
          setTimeout(() => {
            notificationSuccess('Email Verification Successful')
            this.loadPage()
          }, 1000)
        }
      })
    },
    handleModalDelete(){
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        loadingTable: false,
        loadingPopUp: true
      })
      this.deleteUser(this.id).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: true,
              loadingPopUp: false
            })
            notificationSuccess('Delete User Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'User' })
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        }
      })
    }
  }
}
</script>
